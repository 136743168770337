import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import FrontBookingBar from './FrontBookingBar';
import FrontCategorySelector from './FrontCategorySelector';
import { Row, Col, Container } from 'react-bootstrap';

import './FrontPage.css'


class FrontPage extends Component {
    render() {
        const { image, metaTitle, metaDescription, metaKeywords, lastModified } = this.props.main;
        return (
            <div className="FrontPage">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{metaTitle}</title>
                    <meta name="keywords" content={metaKeywords} />
                    <meta name="description" content={metaDescription} />
                    <meta property="og:title" content={metaTitle} />
                    <meta property="og:description" content={metaDescription} />
                    <meta property="og:image" content={image} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="last-modified" content={lastModified} />
                </Helmet>
                <FrontBookingBar image={image} />
                <Container fluid={true}>
                    <Row>
                        <Col className="divBanner"><h1 className="pBanner">Leigðu sendibíl | Thrifty Sendibílar til leigu</h1></Col>
                    </Row>
                    <Row>
                        <Col className="divBanner"><p className="pBanner"> Leigðu sendibíl í stað þess að snattast með draslið í fjölskyldubílnum. Þægindi á hagstæðu verði.</p></Col>
                    </Row>
                </Container>
                <FrontCategorySelector />
            </div >
        );
    }
};

export default FrontPage;