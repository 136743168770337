import React, { Component } from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Car from './Car';
import NoMatch from "./NoMatch";

import './Category.css';

class Category extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allLocations: null,
            data: null,
            cars: [],
            isLoading: true,
            error: null,
        }
    }

    fetchLocations() {
        fetch(process.env.REACT_APP_NODE_BACKEND + '/locations')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    allLocations: data,
                    isLoading: false,
                })
                if (this.state.selectedCarId) {
                    this.setSelectedCar(this.state.selectedCarId);
                }
                if (this.state.selectedLocationId) {
                    this.setSelectedLocation(this.state.selectedLocationId);
                }
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    fetchCarsFromCat(link) {
        fetch(process.env.REACT_APP_NODE_BACKEND + '/categories' + link)
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({ data: data });
                for (let index = 0; index < data.cars.length; index++) {
                    const car = data.cars[index];
                    fetch(process.env.REACT_APP_NODE_BACKEND + '/cars/' + car)
                        // We get the API response and receive data in JSON format...
                        .then(response => {
                            this.setState({ status: response.status })
                            return response.json();
                        })
                        // ...then we update the users state
                        .then(data => {
                            this.setState({ cars: [...this.state.cars, data] });
                        })
                        // Catch any errors we hit and update the app
                        .catch(error => this.setState({ error, isLoading: false }));
                    if (index === data.cars.length - 1) {
                        this.setState({ isLoading: false })
                    }
                }
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    componentDidMount() {
        const parts = this.props.location.pathname.split('/');
        const path = parts.pop();
        this.fetchLocations();
        this.fetchCarsFromCat('/' + path);
    }

    componentWillReceiveProps(newProps) {
        const parts = newProps.match.url.split('/');
        const path = parts.pop();
        this.setState({ error: null, cars: [] })
        this.fetchLocations();
        this.fetchCarsFromCat('/' + path);
    }


    createCarListItem(item) {
        const { allLocations } = this.state;
        return (
            <Col xs={12} sm={6} md={4} key={item._id} ><Car car={item} allLocations={allLocations} /></Col>
        );
    }

    render() {
        
        const { isLoading, cars, error, data, allLocations, status } = this.state;
        if (!isLoading && cars && allLocations) {
            var carList = cars.sort((a, b) => a.order - b.order).map(this.createCarListItem.bind(this));
        }
        if (status === 404) {
            return (<NoMatch />)
        }
        else {
            return (
                <div>
                    {error ? <p className="Error">Oops eitthvað fór úrskeiðis hér {error.message}</p> : null}
                    {!isLoading && data ? (
                        <div>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>{data.metaTitle}</title>
                                <meta name="keywords" content={data.metaKeywords} />
                                <meta name="description" content={data.metaDescription} />
                                <meta property="og:image" content={data.image} />
                                <meta property="og:title" content={data.metaTitle} />
                                <meta property="og:description" content={data.metaDescription} />
                                <meta property="og:url" content={window.location.href} />
                                <meta name="last-modified" content={data.lastModified} />
                            </Helmet>
                            <Container fluid={true} className="Category">
                                <Row>
                                    <Col><h1>{data.name}</h1></Col>
                                </Row>
                                <Row>
                                    <Col><p>{data.description}</p></Col>
                                </Row>
                                <Row >
                                    {carList}
                                </Row>
                            </Container>
                        </div>
                    ) : (
                            <h3>Loading...</h3>
                        )}
                </div >
            );
        }
    }

};



export default Category;